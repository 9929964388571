<template>

  <article-layout>
    <main class="section">
      <div class="container">
        <div class="columns is-centered is-mobile">
          <div class="
            column
            is-three-fifths-desktop
            is-two-thirds-tablet
            is-four-fifths-mobile
          ">

           <!-- Article heading etc. -->
            <h1 class="title is-size-2">
              {{ article.title }}
            </h1>

            <p class="subtitle is-size-4" v-show="article.subtitle">
              <em>{{ article.subtitle }}</em>
            </p>

            <!-- Banner image -->
            <figure class="image">
              <img
                v-lazy="article.image"
                :alt="article.title"
              >
            </figure>

            <!-- The promo article body -->
            <article
              class="content promo"
              v-html="article.body"
            ></article>

            <br><hr><br>

            <div class="columns is-centred">
              <div class="column is-half has-content-centered">
                <add-to-browser
                  medium
                  position="ArticleDesktopView"
                >
                  <span v-t="`buttons.get-pouch`"></span>
                </add-to-browser>
              </div>

              <div class="column is-half has-content-centered">
                <div>
                  <Rating></Rating>

                  <i18n
                    path="home.rating.label"
                    tag="span"
                  >
                    <template  #value>
                      <strong v-t="`home.rating.value`"></strong>
                    </template>

                    <template  #max>
                      <strong>5</strong>
                    </template>
                  </i18n>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </main>
  </article-layout>

</template>

<script>
import { mapState } from 'vuex'

import Rating from '@/views/components/Rating'
import AddToBrowser from '@/views/partials/AddToBrowser'

export default {
  components: { AddToBrowser, Rating },

  computed: mapState({
    article: state => state.article.currentArticle
  })
}
</script>

<style lang="sass" scoped>
p
  padding-top: 1rem

figure
  padding: 2rem 0
</style>
